import React, { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './App.css';

const App = () => {
  const [password, setPassword] = useState('');
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [captchaValid, setCaptchaValid] = useState(false);
  const [queryMade, setQueryMade] = useState(false);

  const validPassword = '900518746-5'; // Contraseña válida para acceder a los documentos

  const handleRecaptchaChange = (value) => {
    setCaptchaValid(!!value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      setError('Por favor, ingresa la contraseña.');
      return;
    }

    if (password !== validPassword) {
      setError('Contraseña incorrecta.');
      return;
    }

    if (!captchaValid) {
      alert('Por favor, completa el reCAPTCHA.');
      return;
    }

    setLoading(true);
    setQueryMade(true);
    const url = `https://hook.us2.make.com/lcwkgx9y6fel3atnbw91fdva7fkauvig`;
    axios.get(url)
      .then(response => {
        if (response.data.length === 0) {
          setDocuments([]);
          setError('No se encontraron documentos.');
        } else {
          setDocuments(response.data);
          setError('');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error al consultar los documentos:', error);
        setLoading(false);
        setError('Error al consultar los documentos. Por favor, intenta nuevamente.');
      });
  };

  return (
    <div className="container">
      <nav className="navbar">
       <h1 className="title">Repositorio de Marketing</h1>
        {/* <img src="logo-mision.png" alt="Left" className="navbar-left" /> */}
        <img src="igniweb.png" alt="Right" className="navbar-right" />
      </nav>
      <div className="content">
        <div className="left-panel">
          <h2 className="title">Consulta tus documentos</h2>
          <p className="subtitle">Ingrese la contraseña para acceder a los documentos.</p>
          <img src='img-new.png' alt="Ilustración de personaje" className="image" />
        </div>
        <div className="right-panel">
          <div className='inputs-container'>
            <form onSubmit={handleSubmit} className="form">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Contraseña"
                className="input same-width"
              />
              
              <ReCAPTCHA
                sitekey="6LfB_CQqAAAAAGXyZqVmq3bXq40kW7FCvnKmkorb"
                onChange={handleRecaptchaChange}
              />
              
              <button 
                type="submit" 
                className="button same-width button-2"
              >Consultar</button>
              
            </form>
          </div>
        </div>
      </div>
      {loading && <p>Cargando...</p>}
      {error && <p className="error">{error}</p>}
      {documents.length > 0 && (
  <div className="documents-section">
    <h3 className="documents-title">Lista de documentos</h3>
    <div className="documents">
      {documents.map((document, index) => (
        <div key={index} className="document-card">
          <img src="file-icon.png" alt={`Documento ${index + 1}`} className="document-image" />
          <div>
            {/* Mostrar el nombre del documento */}
            <span className="document-name">{document.name ? document.name : `Documento ${index + 1}`}</span>
            <div class="button-1">
            <a
              href={`https://hook.us2.make.com/ha6ws271js74n7h12gpi55soxmm2nu2c?name=${document.name}`}
              download
              target="_blank"
              className="download-link"
            >
              Descargar
                  </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {documents.length === 0 && queryMade && !loading && !error && (
        <div className="no-documents">
          No hay documentos disponibles.
        </div>
      )}
      
    </div>    
  );
};

export default App;
